<template>
  <div class="frequency-remind-phone">
    <van-tabs v-model="activeName" @click="onClick" sticky>
      <van-tab title="电话回访" name="a" style="overflow-y: auto;">
        <div class="card">
          <van-row class="title">
            <van-col span="24">
              {{ info.customerName }}
            </van-col>
          </van-row>
          <van-row class="info">
            <van-col span="24">
              授信等级：{{ data.level }}
            </van-col>
          </van-row>
          <van-row class="info">
            <van-col span="16">货量（万吨）：{{ data.weight }}</van-col>
            <van-col span="8" @click="to(data.lineDetail)">航线明细
              <van-icon name="share-o"/>
            </van-col>
          </van-row>
          <van-row class="info">
            <van-col span="16">效益（万元）：{{ data.benefit }}</van-col>
            <van-col span="8" @click="to1(data.problermDetail)">成果方案</van-col>
          </van-row>
        </div>
        <div>
          <van-row class="info-title">基本信息</van-row>
          <van-form @submit="addReplay()">
            <van-row>
              <van-field
                  readonly
                  required
                  label="客户："
                  :value="info.customerName"
                  v-model="info.customerName"
                  placeholder="选择客户"
                  input-align="left"
                  @click="showCompanyName = false"
                  label-width="6.5em"
              />
              <van-popup v-model="showCompanyName" round position="bottom">
                <van-field
                    placeholder="在这里查询客户"
                    v-model="selCustomerValue"
                    left-icon="search"
                />
                <van-picker
                    ref="picker"
                    show-toolbar
                    :columns="options"
                    @cancel="showCompanyName = false"
                    @confirm="onConfirmCompany"
                    default-index="0">
                </van-picker>
              </van-popup>
            </van-row>
            <van-field style="border-radius: 0 0 0 0;" required label="标题："
                       readonly
                       :rules="customerForm.title" placeholder="（例：20230321电话回访）"
                       v-model="info.title"></van-field>
            <van-field required label="复盘类型" placeholder="复盘类型"
                       readonly
                       v-model="info.replayType"></van-field>
            <van-field required label="实际联系人" :rules="customerForm.realContact" placeholder="实际联系人"
                       v-model="info.realContact === 'null'?'':info.realContact"></van-field>
            <van-field label="综合经营情况" :readonly="true" label-width="6.5em"></van-field>
            <van-field required autosize  label="业务量" :rules="customerForm.businessVolume" placeholder="业务量"
                       @click-input="clickItem(info.businessVolume, '业务量', false)"
                       v-model="info.businessVolume === 'null'?'':info.businessVolume" label-width="6.5em" class="lable"></van-field>
            <van-field required autosize  label="盈利情况" :rules="customerForm.profitSituation" placeholder="应收情况"
                       @click-input="clickItem(info.profitSituation, '盈利情况', false)"
                       v-model="info.profitSituation === 'null'?'':info.profitSituation" label-width="6.5em" class="lable"></van-field>
            <van-field label="其他" autosize  placeholder="人员等方面"
                       @click-input="clickItem(info.others, '其他', false)"
                       v-model="info.others === 'null'?'':info.others" label-width="6.5em" class="lable"></van-field>
            <van-field required :rules="customerForm.remarks"
                       label="回访记录" placeholder="回访记录"
                       type="textarea"
                       v-model="info.remarks"></van-field>

            <div v-if="listCf.length>0 || (info.enterpriseType == '船方' && info.enterpriseNature == '船东')">
              <van-row>
                <van-col span="14">
                  <van-field label="船舶运营情况" :readonly="true" label-width="6.5em"></van-field>
                </van-col>
                <van-col span="5" style="color: #289AE1; font-size: 14px; padding-top: 8px">
                  <span  @click="onClickVessel">添加船舶＋</span>
                </van-col>
                <van-col span="5" style="color: #289AE1; font-size: 14px; padding-top: 8px">
                  <span  @click="showModal = true">新建船舶＋</span>
                </van-col>
              </van-row>
              <van-row v-for="(item,index) in listCf">
                <van-col span="21">
                  <van-field required  :label="item.shipName" placeholder="请填写船舶运营情况"
                             :rules="customerForm.shipOperation"
                             @click-input="clickItem(item.shipOperation, item.shipName, false)"
                             v-model="item.shipOperation === 'null'?'':item.shipOperation" label-width="6.5em" class="lable"></van-field>
                </van-col>
                <van-col span="3" style="margin-top: 15px" @click="deleteOne(item)">
                  <van-icon :name="require('@/assets/删减.png')"/>
                </van-col>
              </van-row>

            </div>



            <van-button v-if="!isHidden" type="primary" size="large" native-type="submit">保存
            </van-button>
          </van-form>
        </div>
      </van-tab>
      <van-tab title="走访拜访" name="b" style="overflow-y: auto;">
        <div class="card">
          <van-row class="title">
            <van-col span="24">
              {{ info.customerName }}
            </van-col>
          </van-row>
          <van-row class="info">
            <van-col span="24">
              授信等级：{{ data.level }}
            </van-col>
          </van-row>
          <van-row class="info">
            <van-col span="16">货量（万吨）：{{ data.weight }}</van-col>
            <van-col span="8" @click="to(data.lineDetail)">航线明细
              <van-icon name="share-o"/>
            </van-col>
          </van-row>
          <van-row class="info">
            <van-col span="16">效益（万元）：{{ data.benefit }}</van-col>
            <van-col span="8" @click="to1(data.problermDetail)">成果方案
              <van-icon name="share-o"/>
            </van-col>
          </van-row>
        </div>
        <div>
          <van-row class="info-title">基本信息</van-row>
          <van-form @submit="addReplay1()">
            <van-row>
              <van-field
                  readonly
                  required
                  label="客户："
                  :value="info.customerName"
                  v-model="info.customerName"
                  placeholder="选择客户"
                  input-align="left"
                  @click="showCompanyName = false"
                  label-width="6.5em"
              />
              <van-popup v-model="showCompanyName" round position="bottom">
                <van-field
                    placeholder="在这里查询客户"
                    v-model="selCustomerValue"
                    left-icon="search"
                />
                <van-picker
                    ref="picker"
                    show-toolbar
                    :columns="options"
                    @cancel="showCompanyName = false"
                    @confirm="onConfirmCompany"
                    default-index="0">
                </van-picker>
              </van-popup>
            </van-row>
            <van-field style="border-radius: 0 0 0 0;" required label="标题："
                       :rules="customerForm.title1" placeholder="（例：20230321华东走访）"
                       v-model="info.title1"></van-field>
            <van-field required label="出发时间：" v-model="info.startTime" placeholder="请选择计划出发时间"
                       :rules="customerForm.startTime"
                       @click="show = true"/>
            <van-calendar v-model="show" @confirm="onConfirm" :show-confirm="false"/>
            <van-field required label="返回时间：" v-model="info.endTime" placeholder="请选择计划返回时间"
                       :rules="customerForm.endTime"
                       @click="show1 = true"/>

            <div v-if="listCf.length>0 || (info.enterpriseType == '船方' && info.enterpriseNature == '船东')">
              <van-row>
                <van-col span="14">
                  <van-field label="船舶运营情况" :readonly="true" label-width="6.5em"></van-field>
                </van-col>
                <van-col span="5" style="color: #289AE1; font-size: 14px; padding-top: 8px">
                  <span  @click="onClickVessel">添加船舶＋</span>
                </van-col>
                <van-col span="5" style="color: #289AE1; font-size: 14px; padding-top: 8px">
                  <span  @click="showModal = true">新建船舶＋</span>
                </van-col>
              </van-row>
              <van-row v-for="(item,index) in listCf">
                <van-col span="21">
                  <van-field required  :label="item.shipName" placeholder="请填写船舶运营情况"
                             :rules="customerForm.shipOperation"
                             @click-input="clickItem(item.shipOperation, item.shipName, false)"
                             v-model="item.shipOperation === 'null'?'':item.shipOperation" label-width="6.5em" class="lable"></van-field>
                </van-col>
                <van-col span="3" style="margin-top: 15px" @click="deleteOne(item)">
                  <van-icon :name="require('@/assets/删减.png')"/>
                </van-col>
              </van-row>
            </div>

            <van-field required type="textarea" label="延期原因：" v-model="info.cause" :rules="customerForm.cause"
                       placeholder="请填写延期原因"/>
            <van-calendar v-model="show1" @confirm="onConfirm1" :show-confirm="false"/>



            <van-button v-if="!isHidden" type="primary" size="large" native-type="submit">保存
            </van-button>
          </van-form>
        </div>
      </van-tab>
      <van-tab title="客户来访" name="c" style="overflow-y: auto;">
        <div class="card">
          <van-row class="title">
            <van-col span="24">
              {{ info.customerName }}
            </van-col>
          </van-row>
          <van-row class="info">
            <van-col span="24">
              授信等级：{{ data.level }}
            </van-col>
          </van-row>
          <van-row class="info">
            <van-col span="16">货量（万吨）：{{ data.weight }}</van-col>
            <van-col span="8" @click="to(data.lineDetail)">航线明细
              <van-icon name="share-o"/>
            </van-col>
          </van-row>
          <van-row class="info">
            <van-col span="16">效益（万元）：{{ data.benefit }}</van-col>
            <van-col span="8" @click="to1(data.problermDetail)">成果方案
              <van-icon name="share-o"/>
            </van-col>
          </van-row>
        </div>
        <div>
          <van-row class="info-title">基本信息</van-row>
          <van-form @submit="addReplay2()">
            <van-row>
              <van-field
                  readonly
                  required
                  label="客户："
                  :value="info.customerName"
                  v-model="info.customerName"
                  placeholder="选择客户"
                  input-align="left"
                  @click="showCompanyName = false"
                  label-width="6.5em"
              />
              <van-popup v-model="showCompanyName" round position="bottom">
                <van-field
                    placeholder="在这里查询客户"
                    v-model="selCustomerValue"
                    left-icon="search"
                />
                <van-picker
                    ref="picker"
                    show-toolbar
                    :columns="options"
                    @cancel="showCompanyName = false"
                    @confirm="onConfirmCompany"
                    default-index="0">
                </van-picker>
              </van-popup>
            </van-row>
            <van-field style="border-radius: 0 0 0 0;" required label="标题："
                       readonly
                       :rules="customerForm.title2" placeholder="（例：20230321客户来访）"
                       v-model="info.title2"></van-field>
            <van-field required :rules="customerForm.remarks"
                       label="复盘类型" placeholder="复盘类型"
                       v-model="info.replayType2"></van-field>
            <van-field required label="实际联系人" :rules="customerForm.realContact" placeholder="实际联系人"
                       v-model="info.realContact"></van-field>
            <van-field label="综合经营情况" :readonly="true" label-width="6.5em"></van-field>
            <van-field required autosize  label="业务量" :rules="customerForm.businessVolume" placeholder="业务量"
                       @click-input="clickItem(info.businessVolume, '业务量', false)"
                       v-model="info.businessVolume === 'null'?'':info.businessVolume" label-width="6.5em" class="lable"></van-field>
            <van-field required autosize  label="盈利情况" :rules="customerForm.profitSituation" placeholder="应收情况"
                       @click-input="clickItem(info.profitSituation, '盈利情况', false)"
                       v-model="info.profitSituation === 'null'?'':info.profitSituation" label-width="6.5em" class="lable"></van-field>
            <van-field label="其他" autosize  placeholder="人员等方面"
                       @click-input="clickItem(info.others, '其他', false)"
                       v-model="info.others === 'null'?'':info.others" label-width="6.5em" class="lable"></van-field>
            <van-field required :rules="customerForm.remarks"
                       label="回访记录" placeholder="回访记录" type="textarea"
                       v-model="info.remarks2"></van-field>

            <div v-if="listCf.length>0 || (info.enterpriseType == '船方' && info.enterpriseNature == '船东')">
              <van-row>
                <van-col span="14">
                  <van-field label="船舶运营情况" :readonly="true" label-width="6.5em"></van-field>
                </van-col>
                <van-col span="5" style="color: #289AE1; font-size: 14px; padding-top: 8px">
                  <span  @click="onClickVessel">添加船舶＋</span>
                </van-col>
                <van-col span="5" style="color: #289AE1; font-size: 14px; padding-top: 8px">
                  <span  @click="showModal = true">新建船舶＋</span>
                </van-col>
              </van-row>
              <van-row v-for="(item,index) in listCf">
                <van-col span="21">
                  <van-field required  :label="item.shipName" placeholder="请填写船舶运营情况"
                             :rules="customerForm.shipOperation"
                             @click-input="clickItem(item.shipOperation, item.shipName, false)"
                             v-model="item.shipOperation === 'null'?'':item.shipOperation" label-width="6.5em" class="lable"></van-field>
                </van-col>
                <van-col span="3" style="margin-top: 15px" @click="deleteOne(item)">
                  <van-icon :name="require('@/assets/删减.png')"/>
                </van-col>
              </van-row>
            </div>


            <van-button v-if="!isHidden" type="primary" size="large" native-type="submit">保存
            </van-button>
          </van-form>
        </div>
      </van-tab>
      <dialog-card :data="DialogData" :fieldValue="fieldValue" :show="show2" @success="success" @editVal="editVal"></dialog-card>
    </van-tabs>
    <!--删除船舶-->
    <van-dialog
        v-model="giveup"
        :title=title
        show-cancel-button
        :beforeClose="giveupBtn"
        confirm-button-text='只删除记录'
        cancel-button-text='删除记录和船舶'
    >
    </van-dialog>


    <van-row v-if="showVesselPicker">
      <van-popup v-model="showVesselPicker" round position="bottom">
        <van-picker
            show-toolbar
            :columns="this.vesselItems"
            @cancel="showVesselPicker = false"
            @confirm="onConfirmVessel"
            default-index="0"
        />
      </van-popup>
    </van-row>

    <!--填写船舶信息-->
    <div>
      <Modal :visible="showModal" @close="showModal = false" ref="taskModal"
             id="taskModal1">
        <van-form @submit="saveVessel()">
          <van-field required label="船舶公司：" readonly placeholder="请输入船舶公司" label-width="6.5em"
                     v-model="shipInfo.vesselCompany"></van-field>
          <van-field required label="船舶名称：" placeholder="请输入船名" label-width="6.5em" :rules="customerForm.vesselName"
                     v-model="shipInfo.vesselName"></van-field>
          <van-field label="租船人：" placeholder="请填写租船人" label-width="6.5em"
                     v-model="shipInfo.charterer"></van-field>
          <van-field label="船东信息：" placeholder="请填写船东信息" label-width="6.5em"
                     v-model="shipInfo.customerName"></van-field>
          <van-field label="船东联系人：" placeholder="请填写船东联系人" label-width="6.5em"
                     v-model="shipInfo.contacts"></van-field>
          <van-field label="载重吨：" placeholder="请填写数字" label-width="6.5em" type="number"
                     v-model="shipInfo.dwt"></van-field>
          <van-field label="船长：" placeholder="请填写数字" label-width="6.5em" type="number"
                     v-model="shipInfo.length"></van-field>
          <van-field label="满载吃水：" placeholder="请填写数字" label-width="6.5em" type="number"
                     v-model="shipInfo.draught"></van-field>
          <van-field label="舱口数：" placeholder="请填写数字" label-width="6.5em" type="number"
                     v-model="shipInfo.hatch"></van-field>
          <van-field label="建造年限：" placeholder="请填写建造年限" label-width="6.5em"
                     v-model="shipInfo.period"></van-field>
          <van-field label="主要航线：" placeholder="请填写主要航线" label-width="6.5em"
                     v-model="shipInfo.mainRoutes"></van-field>
          <van-field  label="船舶种类：" placeholder="请填写船舶种类" label-width="6.5em"
                      v-model="shipInfo.vesselType"></van-field>
          <van-field label="主要货种：" placeholder="请填写主要货种" label-width="6.5em"
                     v-model="shipInfo.mainGoods"></van-field>
          <!--              <van-field label="总舱容：" placeholder="请填写总舱容" label-width="6.5em"-->
          <!--                         v-model="shipInfo.toalCapacity"></van-field>-->
          <div class="submit">
            <van-button type="default" native-type="button"
                        @click="showModal=false, vesselInfo ={}">取消
            </van-button>
            <van-button type="info" native-type="submit">确定</van-button>
          </div>
        </van-form>
      </Modal>

    </div>
  </div>
</template>

<script>
import CrmTabBar from "@/components/CrmTabBar";
import DialogCard from "../../components/DigitalCell/DialogCard";
import Modal from './../../components/Modal/MyCustomerModal';
import {deleteBulkShipinfotwo, deleteCfDetail, saveBulkShipinfotwo, addHfVessel} from "../../api/shipInfo";
import {Toast} from "vant";

export default {
  name: "FrequencyRemindPhone",
  components: {CrmTabBar, DialogCard, Modal},
  data() {
    return {
      title: '请选择删除情况',
      giveup: false,
      shipInfo: {
        vesselCompany: '',
        vesselName: '',
        charterer: this.$store.getters.name,
        customerName: '',
        contacts: '',
        cooperationTimes: '',
        dwt: '',
        length: '',
        draught: '',
        hatch: '',
        period: '',
        mainRoutes: '',
        vesselType: '散杂货',
        mainGoods: '',
        toalCapacity: '',
        capacity: '',
      },
      listCf: [],
      showModal: false,
      show2: false,
      fieldValue: '',
      DialogData: {},
      activeName: 'a',
      showCompanyName: false,
      isHidden: false,
      showPicker: false,
      maxDate: new Date(2030, 1, 1),
      lostDate: this.formatter(),
      currentDate: new Date(),
      data: {
        title: '',
        level: '',
        weight: '',
        benefit: '',
        lineDetail: '',
        planDetail: 'planDetail',
        problermDetail: ''
      },
      info: {
        title: '',
        title1: '',
        title2: '',
        customerName: '',
        crmCustomerid: '',
        travelDate: this.formatter(),
        remarks: '',
        remarks2: '',
        replayType: '电话回访',
        replayType1: '走访拜访',
        cause: '',
        replayType2: '客户来访',
        travelType: '002',
        startTime: '',
        endTime: '',
        realContact: '',
        businessVolume: '',
        profitSituation: '',
        others: ''
      },
      date: '',
      date1: '',
      show: false,
      show1: false,
      options: [],
      columns: [],
      selCustomerValue: '',
      loading: false,
      customerForm: {
        customerName: [
          {required: true, message: '请选择客户', trigger: 'blur'},
        ],
        title: [
          {required: true, message: '请输入标题', trigger: 'blur'},
        ],
        title1: [
          {required: true, message: '请输入标题', trigger: 'blur'},
        ],
        title2: [
          {required: true, message: '请输入标题', trigger: 'blur'},
        ],
        startTime: [
          {required: true, message: '请选择出发时间', trigger: 'blur'},
        ],
        realContact: [
          {required: true, message: '', trigger: 'blur'},
        ],
        comOperate: [
          {required: true, message: '', trigger: 'blur'},
        ],
        businessVolume: [
          {required: true, message: '', trigger: 'blur'},
        ],
        profitSituation: [
          {required: true, message: '', trigger: 'blur'},
        ],
        others: [
          {required: true, message: '', trigger: 'blur'},
        ],
        endTime: [
          {required: true, message: '请选择返回时间', trigger: 'blur'},
        ],
        cause: [
          {required: true, message: '请输入延期原因', trigger: 'blur'},
        ],
        remarks: [
          {required: true, message: '', trigger: 'blur'},
        ],
        remarks2: [{required: true, message: '请输入回访记录', trigger: 'blur'},],
        vesselName: [
          {required: true, message: '', trigger: 'blur'},
        ],
        shipOperation:[
          {required: true, message: '', trigger: 'blur'},
        ],
      },
      deleteOption: '',
      deleteId: '',
      showVesselPicker: false,
      vesselItems: [],
    }
  },
  mounted() {
    this.init('a')
  },
  watch: {
    //监听公司名头
    'selCustomerValue': function () {
      if (this.selCustomerValue == '') {
        this.options = []
        for (let i = 0; i < this.columns.length; i++) {
          var array = {}
          array.text = this.columns[i].customerName
          array.value = this.columns[i].crmCustomerid
          this.options.push(array)
        }
      } else {
        this.options = this.options.filter(item => item.text.includes(this.selCustomerValue))
      }
    },
  },
  methods: {
    init(name) {
      this.info.businessVolume = ''
      this.info.profitSituation = ''
      this.info.others = ''
      this.$ajax.post('/api/ajax/planVisit/getBulkCrmPlanvisitList.json',
          {
            createdPeople: '',
            titleId: '',
            travelPlanid: this.$route.params.id,
          }).then(res => {
        if (res.status == 200) {
          console.log(res.data)
          if (res.data.length > 0) {
            if(res.data[0].bulkCrmCfDetailDTO.length>0){
              this.listCf = res.data[0].bulkCrmCfDetailDTO
            }
            this.shipInfo.vesselCompany = res.data[0].customerName
            this.isHidden = res.data[0].travelType.length == 0 ? false : true
            this.data.problermDetail = '/RemainProblermsDetail/' + res.data[0].titleId + '/' + res.data[0].crmCustomerid
            this.data.lineDetail = '/LineDetail/' + res.data[0].customerName
            switch (name) {
              case 'a':
                this.info.title = res.data[0].titleName == '' ? res.data[0].commonName + this.info.replayType : res.data[0].titleName
                this.info.travelType = res.data[0].travelType == '' ? '002' : res.data[0].travelType
                this.info.remarks = res.data[0].remarks
                this.info.realContact = ''
                break
              case 'b':
                this.info.title1 = res.data[0].titleName == '' ? '' : res.data[0].titleName
                this.info.travelType = res.data[0].travelType == '' ? '003' : res.data[0].travelType
                this.info.cause = res.data[0].cause
                break
              case 'c':
                this.info.title2 = res.data[0].titleName == '' ? res.data[0].commonName + this.info.replayType2 : res.data[0].titleName
                this.info.travelType = res.data[0].travelType == '' ? '004' : res.data[0].travelType
                this.info.remarks2 = res.data[0].remarks
                this.info.realContact = ''
                break
            }
            if (res.data[0].travelType == '001' || res.data[0].travelType == '003') {
              this.activeName = 'b'
              this.info.title1 = res.data[0].titleName == '' ? '' : res.data[0].titleName
              this.info.travelType = res.data[0].travelType == '' ? '003' : res.data[0].travelType
              this.info.cause = res.data[0].cause
              this.info.startTime = res.data[0].plandateStart
              this.info.endTime = res.data[0].plandateEnd
              this.info.realContact = ''
            } else if (res.data[0].travelType == '002') {
              this.activeName = 'a'
              this.info.title = res.data[0].titleName == '' ? res.data[0].commonName + this.info.replayType : res.data[0].titleName
              this.info.remarks = res.data[0].remarks
              this.info.realContact = ''
            } else if (res.data[0].travelType == '004') {
              this.activeName = 'c'
              this.info.title2 = res.data[0].titleName == '' ? res.data[0].commonName + this.info.replayType2 : res.data[0].titleName
              this.info.remarks2 = res.data[0].remarks
              this.info.realContact = ''
            } else {
            }
            this.info.customerName = res.data[0].customerName
            this.info.crmCustomerid = res.data[0].crmCustomerid
            this.info.titleId = res.data[0].titleId
            this.info.enterpriseNature = res.data[0].enterpriseNature
            this.info.enterpriseType = res.data[0].enterpriseType
            this.info.realContact = ''
            for (let i = 0; i < res.data.length; i++) {
              var array = {}
              array.text = res.data[i].customerName
              array.value = res.data[i].crmCustomerid
              this.options.push(array)
            }
            //获取客户信息
            this.$ajax.post('/api/ajax/bulkCrmsCusList/getHlxy.json',
                {
                  customerName: res.data[0].customerName
                }).then(res => {
              if (res.status == 200) {
                if (res.data.length > 0) {
                  this.data.weight = res.data[0].hfDTO.weight + "万吨"
                  this.data.benefit = res.data[0].hfDTO.profit + "万元"
                  this.data.level = res.data[0].customerLevels
                }
              }
            }).catch(err => {
              console.log(err)
              this.$message.error('获取客户货量效益失败！');
            })
          } else {
            this.$message.error('该推送已过期，请留意最新推送！');
            setTimeout(() => {
              this.$router.replace({path: '/index'})
            }, 500);
          }
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('初始化失败！');
      })


    },
    to(to) {
      if (to.length > 0) {
        this.$router.push(to)
      } else {
        this.$message.warning('该客户暂无航线明细！');
      }
    },
    to1(to) {
      console.log(to)
      if (to.length == 0) {
        this.$message.warning('暂无成果方案！');
      } else {
        this.$router.push(to)
      }
    },
    addReplay(formName) {
      //更新走访计划
      this.updatePlanvisit()
      this.$ajax.post('/api/ajax/replay/saveBulkCrmReplayByPhone.json',
          {
            titleId: this.info.titleId,
            travelPlanid: this.$route.params.id,
            crmCustomerid: this.info.crmCustomerid,
            titleName: this.info.title,
            customerName: this.info.customerName,
            travelType: this.info.travelType,
            remarks: this.info.remarks,
            realContact: this.info.realContact,
            comOperate: this.info.comOperate,
            businessVolume: this.info.businessVolume,
            profitSituation: this.info.profitSituation,
            others: this.info.others,
            createdPeople: this.$store.getters.userId,
            bulkCrmCfDetailDTO: this.listCf
          }).then(res => {
        if (res.status == 200) {
          this.$message.success('保存成功！');
          setTimeout(() => {
            this.$router.replace({path: '/BulkCrmsTravelTodoList/'})
          }, 200);
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('请注意内容文字长度不超过250字！');
      })
    },
    addReplay1(formName) {
      this.$ajax.post('/api/ajax/planVisit/updateBulkCrmPlanvisitFromGo.json',
          {
            travelPlanid: this.$route.params.id,
            crmCustomerid: this.info.crmCustomerid,
            customerName: this.info.customerName,
            titleName: this.info.title1,
            titleId: this.info.titleId,
            travelType: this.info.travelType,
            cause: this.info.cause,
            createdPeople: this.$store.getters.userId,
            plandateStart: this.info.startTime,
            plandateEnd: this.info.endTime,
            bulkCrmCfDetailDTO: this.listCf
          }).then(res => {
        if (res.status == 200) {
          this.$message.success('保存成功！');
          setTimeout(() => {
            this.$router.replace({path: '/BulkCrmsTravelTodoList/'})
          }, 200);
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('保存失败！');
      })
    },
    addReplay2(formName) {
      //更新走访计划
      this.updatePlanvisit2()
      this.$ajax.post('/api/ajax/replay/saveBulkCrmReplayByPhone.json',
          {
            titleId: this.info.titleId,
            travelPlanid: this.$route.params.id,
            crmCustomerid: this.info.crmCustomerid,
            titleName: this.info.title2,
            customerName: this.info.customerName,
            travelType: this.info.travelType,
            remarks: this.info.remarks2,
            realContact: this.info.realContact,
            comOperate: this.info.comOperate,
            businessVolume: this.info.businessVolume,
            profitSituation: this.info.profitSituation,
            others: this.info.others,
            createdPeople: this.$store.getters.userId,
            bulkCrmCfDetailDTO: this.listCf
          }).then(res => {
        if (res.status == 200) {
          this.$message.success('保存成功！');
          setTimeout(() => {
            this.$router.replace({path: '/BulkCrmsTravelTodoList/'})
          }, 200);
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('保存失败！');
      })
    },
    formatter(time) {
      let nowdata = time == null ? new Date() : new Date(time);
      let year = nowdata.getFullYear();
      let month = nowdata.getMonth() + 1;
      let date = nowdata.getDate();
      let nowtime = year + "-";
      if (month < 10) {
        nowtime += "0";
      }
      nowtime += month + "-";
      if (date < 10) {
        nowtime += "0";
      }
      nowtime += date;
      return nowtime;
    },
    onConfirmCompany(picker, value) {
      console.log(picker, value);
      this.info.customerName = picker.text;
      this.showCompanyName = false;
      this.getCompanyData(picker)
    },
    getCompanyData(picker) {
      this.info.crmCustomerid = picker.value
    },
    onClick(name, title) {
      console.log(name + "****" + title)
      if (name == 'b') {
        this.init(name)
      }
      if (name == 'a') {
        this.init(name)
      }
      if (name == 'c') {
        this.init(name)
      }
    },
    onConfirm(date) {
      this.show = false;
      this.info.startTime = this.formatDate(date);
    },
    onConfirm1(date) {
      this.show1 = false;
      this.info.endTime = this.formatDate(date);
    },
    formatDate(date) {
      return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    },
    updatePlanvisit() {
      this.$ajax.post('/api/ajax/planVisit/updateBulkCrmPlanvisitFromPhone.json',
          {
            travelPlanid: this.$route.params.id,
            crmCustomerid: this.info.crmCustomerid,
            titleName: this.info.title,
            travelType: this.info.travelType,
            remarks: this.info.remarks,
            createdPeople: this.$store.getters.userId
          }).then(res => {
        if (res.status == 200) {

        }
      }).catch(err => {
        console.log(err)
        this.$message.error('保存失败！');
      })
    },
    updatePlanvisit2() {
      this.$ajax.post('/api/ajax/planVisit/updateBulkCrmPlanvisitFromPhone.json',
          {
            travelPlanid: this.$route.params.id,
            crmCustomerid: this.info.crmCustomerid,
            titleName: this.info.title2,
            travelType: this.info.travelType,
            remarks: this.info.remarks2,
            createdPeople: this.$store.getters.userId
          }).then(res => {
        if (res.status == 200) {

        }
      }).catch(err => {
        console.log(err)
        this.$message.error('保存失败！');
      })
    },
    clickItem(val, text, bool){
      this.DialogData.text = text
      this.DialogData.value = val
      this.DialogData.edit = bool
      this.fieldValue = val
      this.show2 = true
    },
    success(res){
      this.show2 = res
    },
    editVal(val){
      if(val.text == '业务推进成果'){
        this.info.pushResult = val.value
      }else if(val.text == '综合经营情况'){
        this.info.comOperate = val.value
      }else if(val.text == '业务量'){
        this.info.businessVolume = val.value
      }else if(val.text == '盈利情况'){
        this.info.profitSituation = val.value
      }else if(val.text == '其他'){
        this.info.others = val.value
      }else{
        if(this.listCf.length>0){
          for(let i=0; i<this.listCf.length; i++){
            if(val.text == this.listCf[i].shipName){
              this.listCf[i].shipOperation = val.value
            }
          }
        }
      }
    },
    saveVessel(){
      saveBulkShipinfotwo({
        shipInformationid: this.info.crmCustomerid,
        vesselName: this.shipInfo.vesselName,
        charterer: this.shipInfo.charterer,
        vesselCompany: this.shipInfo.vesselCompany,
        cooperationTimes: this.shipInfo.cooperationTimes,
        dwt: this.shipInfo.dwt,
        length: this.shipInfo.length,
        draught: this.shipInfo.draught,
        hatch: this.shipInfo.hatch,
        period: this.shipInfo.period,
        mainRoutes: this.shipInfo.mainRoutes,
        vesselType: this.shipInfo.vesselType,
        mainGoods: this.shipInfo.mainGoods,
        toalCapacity: this.shipInfo.toalCapacity,
        capacity: this.shipInfo.capacity,
        createdPeople: this.$store.getters.userId,
      }).then(res => {
        let array={}
        array.shipName=this.shipInfo.vesselName
        array.vesselId=res.vesselId
        array.shipOperation = ''
        array.remarks = '新增'
        array.travelPlanid=this.$route.params.id
        array.replayId=''
        this.listCf.push(array)
        this.clearShip()
        Toast.success('添加船舶成功！');
      }).catch(err => {
        console.log(err)
      });
    },
    clearShip(){
      this.showModal = false
      this.shipInfo.vesselName = ''
      this.shipInfo.customerName = ''
      this.shipInfo.contacts = ''
      this.shipInfo.cooperationTimes = ''
      this.shipInfo.dwt = ''
      this.shipInfo.length = ''
      this.shipInfo.draught = ''
      this.shipInfo.hatch = ''
      this.shipInfo.period = ''
      this.shipInfo.mainRoutes = ''
      this.shipInfo.mainGoods = ''
      this.shipInfo.toalCapacity = ''
      this.shipInfo.capacity = ''
    },
    deleteOne(e){
      console.log(e)
      if(this.listCf.length==1){
        this.$toast({
          message: '至少保留一条船舶！',
          position: 'top'
        });
        return
      }
      console.log(e.remarks)
      let index = this.listCf.findIndex(item => {
        if (item.shipName == e.shipName) {
          return true
        }
      })
      if(e.remarks === "新增"){
        this.deleteOption = e.vesselId
        this.deleteId = e.id
        this.giveup = true
      }
      this.listCf.splice(index, 1)
    },
    giveupBtn(action, done) {
      if (action === 'confirm') {
        done()
      } else if (action === 'cancel') {
        deleteBulkShipinfotwo({
          vesselId: this.deleteOption
        }).then(res => {
        }).catch(err => {
          console.log(err)
        });
        done()
      }
    },
    removeTrailingComma(str) {
      if (str.endsWith(',')) {
        return str.substring(0, str.length - 1);
      }
      return str;
    },
    onClickVessel() {
      var shipName = ''
      if(this.listCf.length>0){
        for(var i=0; i<this.listCf.length; i++){
          shipName += "'" + this.listCf[i].shipName + "',"
        }
        shipName = this.removeTrailingComma(shipName)
      }

      addHfVessel({
        crmCustomerid: this.info.crmCustomerid,
        shipName: shipName
      }).then(res => {
        console.log('船舶',res)
        if(res.length>0){
          this.vesselItems = []
        }
        for(var i=0; i<res.length; i++){
          let array={}
          array.text = res[i].shipName
          array.value = res[i].shipId
          this.vesselItems.push(array)
        }
      }).catch(err => {
        console.log(err)
      });
      this.showVesselPicker = true
    },
    onConfirmVessel(picker, value, index) {
      console.log(picker, value, index);
      let array={}
      array.shipName = picker.text
      array.vesselId= picker.value
      array.shipOperation = ''
      array.remarks = ''
      array.travelPlanid=this.$route.params.id
      array.replayId=''
      this.listCf.push(array)
      this.showVesselPicker = false;
    },
  }
}
</script>

<style scoped>
.frequency-remind-phone{
  background-color: #FFFFFF;
}

.frequency-remind-phone .lable{
  margin-left: 15px;
  font-size: 12px;
  width: 95%;
}

.frequency-remind-phone .title {
  padding: 10px 5px;
  font-weight: 400;
  font-size: 18px;
  color: #EEEEEE;
  font-style: normal;
  letter-spacing: 0;
  line-height: 27px;
  text-decoration: none;
}

.frequency-remind-phone .info {
  text-align: left;
  padding: 10px 5px;
  font-weight: 400;
  font-size: 14px;
  color: #EEEEEE;
  font-style: normal;
  letter-spacing: 0;
  line-height: 19px;
  text-decoration: none;
}

.frequency-remind-phone .card {
  background: #318EF5;
}

.frequency-remind-phone .info-title {
  padding: 10px 10px 5px;
  text-align: left;
  font-weight: 400;
  font-size: 14px;
  color: #AAA8A8;
  font-style: normal;
  letter-spacing: 0;
  line-height: 18px;
  text-decoration: none;
}

.submit {
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin: 20px 0;
}

.submit .van-button {
  width: 150px;
}

</style>

<style>
.frequency-remind-phone .van-tab {
  color: #FFF7FD;
  font-weight: 500;
  background-color: #0084F8;
}
</style>